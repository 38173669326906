<template>
    <div class="home">
        <div class="row mx-0 px-3 shadow-head-home">
            <div class="col-auto f-600 text-gris f-28 pl-4 pr-0 ">
                Mis cursos
            </div>
            <div v-show="$usuario.rol_nombre!='lector' && $route.name == 'mis-cursos'" class="col-auto ml-auto">
                <div class="bg-general text-white br-12 py-2 px-5 cr-pointer" @click="crearCarpeta">
                    Crear Carpeta
                </div>
            </div>
        </div>
        <div class="row mx-4 mt-3 py-2 border-bottom-routes">
            <router-link 
            v-for="(menu, m) in menus" 
            :key="m" 
            class="col-auto f-18 px-3" 
            :class="$route.name == menu.ruta || (menu.id==1 && $route.name =='mis-cursos.mosaico') ? 'active-tab' : 'tab'"
            :to="{name : menu.ruta, params: menu.params }"
            >
                {{ menu.titulo }}
            </router-link>
            <div v-if="showMosaico" class="col-auto d-flex text-general f-18 ml-auto">
                {{ $route.name == 'mis-cursos.mosaico' ? 'Vista en Lista' : 'Vista Mosaico' }}
                <router-link :to="{name: 'mis-cursos'}">
                    <el-tooltip placement="bottom" content="Vista Mosaico" effect="light">
                        <i class="icon-view-list cr-pointer mx-1" :class="$route.name== 'mis-cursos' ? 'active-view' : 'view'" @click="view = 1" />
                    </el-tooltip>
                </router-link>
                <div class="border" />
                <router-link :to="{name: 'mis-cursos.mosaico'}">
                    <el-tooltip placement="bottom" content="Vista en Lista" effect="light">
                        <i class="icon-view-module cr-pointer mx-1" :class="$route.name == 'mis-cursos.mosaico' ? 'active-view' : 'view'" @click="view = 2" />
                    </el-tooltip>
                </router-link>
            </div>
        </div>
        <router-view />
    </div>
</template>

<script>

import { mapGetters } from 'vuex'
export default {
    name: 'Home',
    middleware:['auth'],
    data(){
        return {
            conf_tabs: {
                1:[
                    { titulo: 'Mis Cursos', ruta: 'mis-cursos',   id: 1, params: {}},
                    { titulo: 'Responsable', ruta: 'responsable', id: 2, params: {} },

                ],
                2:[
                    { titulo: 'Mis Cursos', ruta: 'mis-cursos',   id: 1, params: {}},
                    { titulo: 'Responsable', ruta: 'responsable', id: 2, params: {} },
                    { titulo: 'Configurar', ruta: 'configurar',   id: 3, params: {} }
                ]
            },
            tab_actual: 1,
            view: 1,
            showMosaico: false
        }
    },
    computed:{
        ...mapGetters({
            avance: 'general/avance'
        }),
        menus(){
            return this.conf_tabs[this.$usuario.rol].filter(el => this.avance.reponsable || el.id != 2)
        }
    },
    watch: {
        '$route.name': function(val){
            this.verMosaico()
        }
    },
    mounted(){
        this.verMosaico()
    
    },
    methods: {
        verMosaico(){
            this.showMosaico =  ['mis-cursos.mosaico','mis-cursos' ].includes(this.$route.name)
        },
        crearCarpeta(){
            this.$router.push({name: 'mis-cursos.editar.carpeta'})
        }
    }
}
</script>

<style lang="scss" scoped>
.shadow-head-home{
  box-shadow: 0px 3px 6px #0000001A;
  border-radius: 12px 12px 0px 0px;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 5px;
}
.shadow-button{
  box-shadow: 0px 3px 6px #0000001F;
}
.border-bottom-routes{
  border-bottom:1px solid var(--color-general);
}
.border-grey{
  border: 5px solid var(--color-grey);
  border-radius: 5px;
}
.active-view{ color: var(--color-general); }
.view{
  color: #DFDFDF;
  &:hover{
    color: var(--color-general) !important;
  }
}
.active-tab{ color: #707070 !important; font-weight: 600; }
.tab{ color: #707070 !important; font-weight: 400; }
</style>